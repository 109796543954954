import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import MotForm from "../../components/mot-form";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import Badges from "../../components/badges";
import BumperBanner from "../../components/bumber-banner";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import { Button } from "react-bootstrap";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const AccidentRepairPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "accident-repair" }) {
				slug
				carAccidentRepairFields {
					accidentRepairBanner {
						accidentRepairBannerHeading
						accidentRepairBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairContentSection {
						accidentRepairContentHeading
						accidentRepairContentText
						accidentRepairContentButton {
							title
							target
							url
						}
					}
					accidentRepairLogoSlider {
						accidentRepairLogoSliderHeading
						accidentRepairLogoSliderNotice
						accidentRepairLogos {
							nodes {
								... on WpVehicleLogo {
									vehicleLogoFields {
										logoUrl
										logoImage {
											node {
												altText
												localFile {
													publicURL
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					accidentRepairLeftImageRightContent {
						accidentRepairRightContentHeading
						accidentRepairRightContentText
						accidentRepairRightContentButton {
							title
							target
							url
						}
						accidentRepairLeftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairLeftContentRightImage {
						accidentRepairLeftContent
						accidentRepairRightImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairBackgroundImageHeading {
						accidentRepairImageHeading {
							title
							target
							url
						}
						accidentRepairBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairReviews {
						accidentRepairReviewsHeading
					}
					accidentRepairBgImageOverlayContent {
						accidentRepairOverlayHeading
						accidentRepairOverlayContent
						accidentRepairOverlayButton {
							title
							target
							url
						}
						accidentRepairBgImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairBookingContentRightImage {
						accidentRepairBookingHeading
						accidentRepairBookingContent
						accidentRepairBookingButton {
							title
							target
							url
						}
						accidentRepairBookingImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					accidentRepairVideo {
						accidentRepairUploadVideo {
							node {
								altText
								mediaItemUrl
							}
						}
					}
					accidentRepairBumper {
						accidentRepairBumperHeading
						accidentRepairBumperButton {
							title
							target
							url
						}
						accidentRepairBumperNumbers {
							accidentRepairBumperNumberHeading
							accidentRepairBumperNumberText
							accidentRepairBumperNumberPostfix
							accidentRepairBumperNumberPrefix
						}
					}
					accidentRepairForm {
						accidentRepairFormHeading
					}
					accidentRepairServicesCards {
						accidentRepairServicesCardsHeading
						accidentServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, carAccidentRepairFields, slug },
	} = data;

	const {
		accidentRepairBanner,
		accidentRepairContentSection,
		accidentRepairLogoSlider,
		accidentRepairLeftImageRightContent,
		accidentRepairLeftContentRightImage,
		accidentRepairBackgroundImageHeading,
		accidentRepairReviews,
		accidentRepairBgImageOverlayContent,
		accidentRepairBookingContentRightImage,
		accidentRepairVideo,
		accidentRepairBumper,
		accidentRepairForm,
		accidentRepairServicesCards,
	} = carAccidentRepairFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Car Accident Repair",
				item: {
					url: `${siteUrl}/what-we-do/accident-repair`,
					id: `${siteUrl}/what-we-do/accident-repair`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{accidentRepairBanner && (
					<section>
						<ServiceHero
							title={accidentRepairBanner.accidentRepairBannerHeading}
							backgroundImage={
								accidentRepairBanner.accidentRepairBannerBackgroundImage.node
									?.localFile.childImageSharp.gatsbyImageData
							}
							imgAlt={
								accidentRepairBanner.accidentRepairBannerBackgroundImage.node
									?.altText
							}
						/>
					</section>
				)}
				{accidentRepairContentSection &&
					!checkPropertiesForNull(accidentRepairContentSection, [
						"accidentRepairContentHeading",
						"accidentRepairContentText",
					]) && (
						<section className="pt-5  pt-lg-8 position-relative">
							<Container>
								<Row>
									<Col className="position-relative">
										<h2 className=" text-center pb-5">
											{
												accidentRepairContentSection.accidentRepairContentHeading
											}
										</h2>
										<div>
											<SafeHtmlParser
												htmlContent={
													accidentRepairContentSection?.accidentRepairContentText
												}
											/>
										</div>
										{accidentRepairContentSection.accidentRepairContentButton &&
											accidentRepairContentSection.accidentRepairContentButton
												.url && (
												<Row>
													<Col className="text-center text-md-start">
														<Button
															style={{ borderRadius: "0px" }}
															variant="secondary"
															as={Link}
															to={
																accidentRepairContentSection
																	.accidentRepairContentButton.url
															}
															className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
														>
															{
																accidentRepairContentSection
																	.accidentRepairContentButton.title
															}
														</Button>
													</Col>
												</Row>
											)}
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{accidentRepairLogoSlider &&
					!checkPropertiesForNull(accidentRepairLogoSlider, [
						"accidentRepairLogoSliderHeading",
						"accidentRepairLogos",
					]) && (
						<Badges
							title={accidentRepairLogoSlider.accidentRepairLogoSliderHeading}
							text={accidentRepairLogoSlider.accidentRepairLogoSliderNotice}
							logos={accidentRepairLogoSlider.accidentRepairLogos?.nodes}
						/>
					)}
				{accidentRepairLeftImageRightContent &&
					!checkPropertiesForNull(accidentRepairLeftImageRightContent, [
						"accidentRepairRightContentHeading",
						"accidentRepairLeftImage",
					]) && (
						<div className="pb-5 ">
							<ImageSideContainer
								image={
									accidentRepairLeftImageRightContent.accidentRepairLeftImage
										.node?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={
									accidentRepairLeftImageRightContent.accidentRepairLeftImage
										.node?.altText
								}
								title={
									accidentRepairLeftImageRightContent.accidentRepairRightContentHeading
								}
								order="first"
								imagePadding=" pb-5 pb-lg-0    "
								text={
									accidentRepairLeftImageRightContent.accidentRepairRightContentText
								}
								ctaButton={
									accidentRepairLeftImageRightContent.accidentRepairRightContentButton
								}
							/>
						</div>
					)}
				{accidentRepairLeftContentRightImage &&
					!checkPropertiesForNull(accidentRepairLeftContentRightImage, [
						"accidentRepairLeftContent",
					]) && (
						<div className="pb-5 pb-lg-8">
							<ImageSideContainer
								image={
									accidentRepairLeftContentRightImage.accidentRepairRightImage
										.node?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={
									accidentRepairLeftContentRightImage.accidentRepairRightImage
										.node?.altText
								}
								order="last"
								imagePadding=" pb-5 pb-lg-0    "
								text={
									accidentRepairLeftContentRightImage.accidentRepairLeftContent
								}
							/>
						</div>
					)}
				{accidentRepairBackgroundImageHeading &&
					!checkPropertiesForNull(accidentRepairBackgroundImageHeading, [
						"accidentRepairImageHeading",
					]) && (
						<section>
							{" "}
							<BgImage
								className="w-100 "
								alt=""
								image={getImage(
									accidentRepairBackgroundImageHeading
										.accidentRepairBackgroundImage.node?.localFile
										.childImageSharp.gatsbyImageData
								)}
							>
								<Container
									style={{ zIndex: 2 }}
									className="h-100 py-5 pt-lg-10"
								>
									<Row className="align-items-end h-100 py-5 pt-lg-10">
										<Col className="text-center pt-lg-5 mx-auto" xl={8}>
											<a
												target="_blank"
												rel="noreferrer"
												href={
													accidentRepairBackgroundImageHeading
														.accidentRepairImageHeading.url
												}
												className="white-link inter-bold fs-2"
											>
												<SafeHtmlParser
													htmlContent={
														accidentRepairBackgroundImageHeading
															?.accidentRepairImageHeading.title
													}
												/>
											</a>
										</Col>
									</Row>
								</Container>
							</BgImage>
						</section>
					)}
				<section>
					<Reviews
						title={accidentRepairReviews?.accidentRepairReviewsHeading}
					/>
				</section>

				{accidentRepairBgImageOverlayContent &&
					!checkPropertiesForNull(accidentRepairBgImageOverlayContent, [
						"accidentRepairOverlayHeading",
					]) && (
						<section>
							{" "}
							<BgImage
								style={{ backgroundPosition: "top" }}
								className=""
								alt=""
								image={getImage(
									accidentRepairBgImageOverlayContent?.accidentRepairBgImage
										.node?.localFile.childImageSharp.gatsbyImageData
								)}
							>
								<Container
									style={{ zIndex: 2 }}
									className="position-relative py-xl-10 py-5 "
								>
									<Row className="justify-content-end">
										<Col lg={7} className="">
											<div className="position-relative  p-5">
												<div
													style={{
														zIndex: 1,
														opacity: 0.8,
														backgroundColor: "white",
													}}
													className="position-absolute h-100 w-100 start-0 bottom-0"
												></div>
												<div
													className="position-relative"
													style={{ zIndex: 2 }}
												>
													<h2 className=" pb-4">
														{
															accidentRepairBgImageOverlayContent.accidentRepairOverlayHeading
														}
													</h2>

													<SafeHtmlParser
														htmlContent={
															accidentRepairBgImageOverlayContent?.accidentRepairOverlayContent
														}
													/>
													{accidentRepairBgImageOverlayContent.accidentRepairOverlayButton &&
														accidentRepairBgImageOverlayContent
															.accidentRepairOverlayButton.url && (
															<Row>
																<Col className="text-center text-md-start">
																	<Button
																		style={{ borderRadius: "0px" }}
																		variant="secondary"
																		as={Link}
																		to={
																			accidentRepairBgImageOverlayContent
																				.accidentRepairOverlayButton.url
																		}
																		className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
																	>
																		{
																			accidentRepairBgImageOverlayContent
																				.accidentRepairOverlayButton.title
																		}
																	</Button>
																</Col>
															</Row>
														)}
												</div>
											</div>
										</Col>
									</Row>
								</Container>
							</BgImage>
						</section>
					)}
				{accidentRepairBookingContentRightImage &&
					!checkPropertiesForNull(accidentRepairBookingContentRightImage, [
						"accidentRepairBookingHeading",
					]) && (
						<div className="py-5 py-lg-8">
							<ImageSideContainer
								image={
									accidentRepairBookingContentRightImage
										.accidentRepairBookingImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									accidentRepairBookingContentRightImage
										.accidentRepairBookingImage.node?.altText
								}
								title={
									accidentRepairBookingContentRightImage.accidentRepairBookingHeading
								}
								order="last"
								imagePadding=" pb-5 pb-lg-0    "
								text={
									accidentRepairBookingContentRightImage.accidentRepairBookingContent
								}
								ctaButton={
									accidentRepairBookingContentRightImage.accidentRepairBookingButton
								}
							/>
						</div>
					)}
				{accidentRepairVideo &&
					!checkPropertiesForNull(accidentRepairVideo, [
						"accidentRepairUploadVideo",
					]) && (
						<section className="bg-white">
							<Container className="pb-5 pb-lg-7">
								<Row>
									<Col>
										<div>
											<video style={{ width: "100%" }} controls>
												<source
													src={
														accidentRepairVideo.accidentRepairUploadVideo.node
															?.mediaItemUrl
													}
													type="video/mp4"
												/>
												Sorry, your browser doesn't support videos.
											</video>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				{accidentRepairBumper &&
					!checkPropertiesForNull(accidentRepairBumper, [
						"accidentRepairBumper",
						"accidentRepairBumperNumbers",
					]) && (
						<BumperBanner
							title={accidentRepairBumper.accidentRepairBumperHeading}
							items={accidentRepairBumper.accidentRepairBumperNumbers}
							ctaButton={accidentRepairBumper.accidentRepairBumperButton}
						/>
					)}

				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{accidentRepairForm?.accidentRepairFormHeading ||
										"BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Car Accident Repair" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {accidentRepairServicesCards &&
          !checkPropertiesForNull(accidentRepairServicesCards, [
            "accidentRepairServicesCardsHeading",
            "accidentServicesCards",
          ]) && (
            <OurServicesSection
              title={
                accidentRepairServicesCards.accidentRepairServicesCardsHeading
              }
              services={
                accidentRepairServicesCards.accidentServicesCards?.nodes
              }
            />
          )} */}
			</Layout>
		</>
	);
};

export default AccidentRepairPage;
